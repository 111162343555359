import React from "react"
import logo from "../images/boiseraie_logo.png"
import logo_white from "../images/boiseraie_logo_white.png"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { MenuModal } from "./MenuModal.js"

export default function Layout({ children }) {
  return (
    <div>
      <header className="container mx-auto flex items-center my-6 px-8 md:px-0 relative">
        <ul className="flex-1">
          <li><a href="/"><img src={logo} alt="Logo" className="h-12 md:h-20"/></a></li>
        </ul>
        <MenuModal />
        <ul className="hidden md:flex items-center">
          <li className="px-4">
            <AnchorLink to="/#le-centre" title="Le Centre" className="green-color-hover">Le centre</AnchorLink>
          </li>
          <li className="px-4">
            <AnchorLink to="/#nos-specialites" title="Nos spécialités" className="green-color-hover">Nos spécialités</AnchorLink>
          </li>
          <li className="px-4">
            <AnchorLink to="/#notre-equipe" title="Notre équipe" className="green-color-hover">Nos praticiens</AnchorLink>
          </li>
          <li className="px-4">
            <AnchorLink to="/#nous-trouver" title="Nous trouver" className="green-color-hover">Nous trouver</AnchorLink>
          </li>
          <li className="pl-4">
            <a href="https://www.doctolib.fr/cabinet-de-kinesitherapie/marseille/cabinet-la-boiseraie" target="_blank" rel="noreferrer">
              <button className="btn-primary shadow-lg">Prendre rendez-vous</button>
            </a>
          </li>
        </ul>
      </header>
      {children}
      <footer className="green-background">
        <div className="container mx-auto flex flex-wrap py-24">
          <div className="w-full md:w-1/4">
            <div className="flex justify-center">
              <a href="/">
                <img src={logo_white} alt="Logo" className="h-20" />
              </a>
            </div>
          </div>
          <div className="hidden md:block md:w-1/4">
            <ul>
              <li className="py-1"><AnchorLink to="/#le-centre" title="Le Centre">Le centre</AnchorLink></li>
              <li className="py-1"><AnchorLink to="/#nos-specialites" title="Nos spécialités">Nos spécialités</AnchorLink></li>
              <li className="py-1"><AnchorLink to="/#notre-equipe" title="Notre équipe">Notre équipe</AnchorLink></li>
              <li className="py-1"><AnchorLink to="/#nous-trouver" title="Nous trouver">Nous trouver</AnchorLink></li>
              <li className="py-1"><a href="https://www.doctolib.fr/cabinet-de-kinesitherapie/marseille/cabinet-la-boiseraie" target="_blank" rel="noreferrer">Prendre rendez-vous</a></li>
            </ul>
          </div>
          <div className="hidden md:block md:w-1/4">
            <div className="tracking-wider mb-2">NOUS CONTACTER</div>
            <ul>
              <li className="mb-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" className="inline mr-2"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
                Le Cabinet : <a href="tel:09 75 39 70 30">09 75 39 70 30</a>
              </li>
              <li className="mb-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" className="inline mr-2"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
                Camille Guyot : <a href="tel:06 25 24 14 15">06 25 24 14 15</a>
              </li>
              <li className="mb-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" className="inline mr-2"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
                Claire Bontoux : <a href="tel:06 29 54 18 86">06 29 54 18 86</a>
              </li>
              <li>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" className="inline mr-2"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
                Email : <a href="mailto:cabinekine.c2@gmail.com">cabinekine.c2@gmail.com</a>
              </li>
            </ul>
          </div>
          <div className="hidden md:block w-1/4">
            <h3 className="tracking-wider mb-2">LES HORAIRES</h3>
            <ul>
              <li>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" className="inline mr-2"><circle cx="12" cy="12" r="10"></circle><polyline points="12 6 12 12 16 14"></polyline></svg>
                Du Lundi au vendredi de 8h00 à 20h00
              </li>
            </ul>
          </div>
        </div>
        <ul className="flex justify-center py-4 text-sm">
          <li className="px-2">La Boiseraie 2021</li>
          <li className="px-2"> - </li>
          <li className="px-2">Mentions légales</li>
        </ul>
      </footer>
    </div>
  )
}