import * as React from "react"
import { Helmet } from 'react-helmet'
import { JsonLd } from '../components/JsonLd'
import Layout from "../components/layout"
import "./styles.css"
import reeducation_fonctionnelle from "../images/reeducation_fonctionnelle.jpg"
import therapie_manuelle from "../images/therapie_manuelle.jpg"
import bronchio from "../images/bronchio.jpg"
import mckenzie from "../images/mckenzie.jpg"
import epaule from "../images/epaule.jpg"
import physio from "../images/physio.jpg"
import presso from "../images/presso.jpg"
import accueil from "../images/la_boiseraie_salle_attente.jpg"
import entree from "../images/la_boiseraie_entree.jpg"
import machines from "../images/la_boiseraie_machines.jpg"
import machines_2 from "../images/la_boiseraie_machines_2.jpg"
import gymnase from "../images/la_boiseraie_gymnase.jpg"
import gymnase_2 from "../images/la_boiseraie_gymnase_2.jpg"
import gymnase_3 from "../images/la_boiseraie_gymnase_3.jpg"
import box from "../images/la_boiseraie_box.jpg"
import kine from "../images/kinesitherapie.png"
import cardio from "../images/reeducation_cardio_respiratoire.jpg"
import claire from "../images/claire_bontoux_kinesitherapeute.jpg"
import camille from "../images/camille_guyot_kinesitherapeute.jpg"
import Map from './map'


import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';

new Swiper('.swiper-container', {
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  breakpoints: {
    320: {
      slidesPerView: 2,
      spaceBetween: 0
    },
    1040: {
      slidesPerView: 4,
      spaceBetween: 0
    }
  }
});

new Swiper('.swiper-cours', {
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  breakpoints: {
    320: {
      slidesPerView: 1,
      spaceBetween: 30,
    },
    1040: {
      slidesPerView: 3,
      spaceBetween: 30
    }
  }
});

const IndexPage = () => {
  return (
    <Layout>
      <Helmet>
        <html lang="fr" />
        <title>Centre de Kinésithérapie Marseille 13012 - La Boiseraie</title>
        <meta
          name="description"
          content="Notre centre de kinésithérapie situé entre la Fourragère et Saint-Barnabé à Marseille 13012 vous accueille pour votre rééducation."
        />
      </Helmet>
      <JsonLd>
        {{
          '@context': 'https://schema.org',
          '@type': 'Organization',
          url: 'https://www.laboiseraie-kine.com',
          name: 'Centre de Kinésithérapie - La Boiseraie',
          contactPoint: {
            '@type': 'ContactPoint',
            telephone: '06 25 24 14 15 / 06 29 54 18 86',
            contactType: 'Prise de rendez-vous',
          },
        }}
      </JsonLd>
      <title>Cabinet de Kinésithérapie à Marseille 12ème</title>
      <div className="jumbotron">
        <div className="container mx-auto py-60 px-8 md:px-16">
          <h1 className="w-full text-5xl md:text-6xl text-center text-white">Centre de Kinésithérapie à Marseille</h1>
          <p className="w-full text-center text-white">Notre équipe vous accueille dans son nouveau cabinet de kinésithérapie dans le 12ème arrondissement de Marseille.</p>
        </div>
      </div>
      <div className="flex flex-wrap" id="le-centre">
        <div className="green-background w-full md:w-1/2 px-8 md:px-16 py-24">
          <div className="flex items-center mb-4">
            <div className="flex-1">
              <h2 className="text-5xl my-4">Le Centre</h2>
            </div>
            <div className="flex justify-end">
              <img src={kine} alt="kinesitherapie" className="h-16 w-16 md:w-24 md:h-24"/>
            </div>
          </div>
          <p className="mb-4">Notre cabinet de Kinésithérapie est un centre dédié à votre rééducation. À vocation générale, le cabinet vous reçoit en soin pour tout type de pathologie (orthopédie, rumathologie, pédiatrie, rééducation respiratoire du nourrisson, etc.).</p>
          <p className="mb-4">Axée sur une approche active et fonctionnelle de la kinésithérapie, notre équipe jeune et dynamique vous proposera une rééducation personnalisée et des soins adaptés à vos besoins.</p>
          <p className="mb-4">Le centre dispose d'un plateau technique composé d'un gymnase où nous effectuons les exercices de rééducation avec du matériel adapté, et d'un gymnase équipé d'appareils de rééducation.</p>
          <p className="mb-4">Le cabinet dispose également de 4 box pour la prise en charge manuelle et individuelle ainsi que pour la réalisation de nos bilans.</p>
          <p className="mb-4">Également formés à des techniques spécifiques de rééducation comme la méthode McKenzie, le concept CGE et la thérpaie manuelle, nos praticiens suivent régulièrement de nouvelles formations afin de vous proposer les techniques les plus appropriées pour votre rééducation.</p>
        </div>
        <div className="w-full md:w-1/2 px-8 md:px-16 py-24">
          <h2 className="text-5xl my-4">Informations pratiques</h2>
          <h3 className="tracking-wider mt-4 mb-2">L'ADRESSE</h3>
          <p>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" className="inline mr-2"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg>
            7 rue gaston de Flotte, Bâtiment HOM, 13012, Marseille
          </p>
          <h3 className="tracking-wider mt-4 mb-2">NOUS CONTACTER</h3>
          <ul>
          <li className="mb-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" className="inline mr-2"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
              Le Cabinet : <a href="tel:09 75 39 70 30">09 75 39 70 30</a>
            </li>
            <li className="mb-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" className="inline mr-2"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
              Camille Guyot : <a href="tel:06 25 24 14 15">06 25 24 14 15</a>
            </li>
            <li className="mb-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" className="inline mr-2"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
              Claire Bontoux : <a href="tel:06 29 54 18 86">06 29 54 18 86</a>
            </li>
            <li>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" className="inline mr-2"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
              Email : <a href="mailto:cabinekine.c2@gmail.com">cabinekine.c2@gmail.com</a>
            </li>
          </ul>
          <h3 className="tracking-wider mt-4 mb-2">LES HORAIRES</h3>
          <p>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" className="inline mr-2"><circle cx="12" cy="12" r="10"></circle><polyline points="12 6 12 12 16 14"></polyline></svg>
            Du Lundi au vendredi de 8h00 à 20h00
          </p>
        </div>
      </div>
      <div className="swiper-container">
        <div className="swiper-wrapper">
          <div className="swiper-slide">
            <img src={box} alt="XXX"/>
          </div>
          <div className="swiper-slide">
            <img src={entree} alt="XXX"/>
          </div>
          <div className="swiper-slide">
            <img src={gymnase} alt="XXX"/>
          </div>
          <div className="swiper-slide">
            <img src={machines} alt="XXX"/>
          </div>
          <div className="swiper-slide">
            <img src={gymnase_2} alt="XXX"/>
          </div>
          <div className="swiper-slide">
            <img src={machines_2} alt="XXX"/>
          </div>
          <div className="swiper-slide">
            <img src={gymnase_3} alt="XXX"/>
          </div>
          <div className="swiper-slide">
            <img src={accueil} alt="XXX"/>
          </div>
        </div>
        <div className="swiper-button-prev"></div>
        <div className="swiper-button-next"></div>
      </div>
      <div className="container mx-auto pt-32 md:pb-16" id="nos-specialites">
        <h2 className="text-5xl px-4 md:px-2 mb-8">Nos spécialités</h2>
        <div className="flex flex-wrap">
          <div className="w-1/2 md:w-1/4 md:px-2 md:mb-4">
            <div className="relative">
              <img src={reeducation_fonctionnelle} alt="reeducation fonctionnelle"/>
              <div className="absolute inset-0 bg-black opacity-25"></div>
              <div className="absolute inset-0 flex items-center justify-center text-center text-white font-semibold tracking-wider">
                RÉÉDUCTION FONCTIONNELLE
              </div>
            </div>
          </div>
          <div className="w-1/2 md:w-1/4 md:px-2 md:mb-4">
            <div className="relative">
              <img src={therapie_manuelle} alt="therapie manuelle"/>
              <div className="absolute inset-0 bg-black opacity-25"></div>
              <div className="absolute inset-0 flex items-center justify-center text-center text-white font-semibold tracking-wider">
                THÉRAPIES MANUELLES
              </div>
            </div>
          </div>
          <div className="w-1/2 md:w-1/4 md:px-2 md:mb-4">
            <div className="relative">
              <img src={physio} alt="physiotherapie"/>
              <div className="absolute inset-0 bg-black opacity-25"></div>
              <div className="absolute inset-0 flex items-center justify-center text-center px-2 text-white font-semibold tracking-wider">
                PHYSIOTHÉRAPIE
              </div>
            </div>
          </div>
          <div className="w-1/2 md:w-1/4 md:px-2 md:mb-4">
            <div className="relative">
              <img src={presso} alt="pressotherapie"/>
              <div className="absolute inset-0 bg-black opacity-25"></div>
              <div className="absolute inset-0 flex items-center justify-center text-center px-2 text-white font-semibold tracking-wider">
                PRESSOTHÉRAPIE
              </div>
            </div>
          </div>
          <div className="w-1/2 md:w-1/4 md:px-2 md:mb-4">
            <div className="relative">
              <img src={bronchio} alt="Bronchiolite"/>
              <div className="absolute inset-0 bg-black opacity-25"></div>
              <div className="absolute inset-0 flex items-center justify-center text-center px-2 text-white font-semibold tracking-wider">
                RÉÉDUCATION RESPIRATOIRE DU NOURRISSON
              </div>
            </div>
          </div>
          <div className="w-1/2 md:w-1/4 md:px-2 md:mb-4">
            <div className="relative">
              <img src={cardio} alt="XXX"/>
              <div className="absolute inset-0 bg-black opacity-25"></div>
              <div className="absolute inset-0 flex items-center justify-center text-center px-2 text-white font-semibold tracking-wider">
                RÉÉDUCATION CARDIO-RESPIRATOIRE
              </div>
            </div>
          </div>
          <div className="w-1/2 md:w-1/4 md:px-2 md:mb-4">
            <div className="relative">
              <img src={mckenzie} alt="Mc Kenzie"/>
              <div className="absolute inset-0 bg-black opacity-25"></div>
              <div className="absolute inset-0 flex items-center justify-center text-center px-2 text-white font-semibold tracking-wider">
                MC KENZIE
              </div>
            </div>
          </div>
          <div className="w-1/2 md:w-1/4 md:px-2 md:mb-4">
            <div className="relative">
              <img src={epaule} alt="concept CGE epaule"/>
              <div className="absolute inset-0 bg-black opacity-25"></div>
              <div className="absolute inset-0 flex items-center justify-center text-center px-2 text-white font-semibold tracking-wider">
                CONCEPT CGE - ÉPAULE
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto pt-16 md:pb-32" id="nos-cours-collectifs">
        <h2 className="text-5xl px-4 md:px-2 mb-8">Nos cours collectifs</h2>
        <div className="relative overflow-hidden">
          <div className="swiper-cours">
            <div className="swiper-wrapper">
              <div className="swiper-slide py-32 green-background flex items-center justify-center text-center px-2 text-white font-semibold tracking-wider">
                  PILATES
              </div>
              <div className="swiper-slide py-32 green-background flex items-center justify-center text-center px-2 text-white font-semibold tracking-wider">
                  YOGALATES
              </div>
              <div className="swiper-slide py-32 green-background flex items-center justify-center text-center px-2 text-white font-semibold tracking-wider">
                  STRETCHING
              </div>
            </div>
            <div className="swiper-button-prev"></div>
            <div className="swiper-button-next"></div>
          </div>
        </div>
      </div>
      <div className="blue-background" id="notre-equipe">
        <div className="container mx-auto py-32 px-4">
          <h2 className="text-5xl px-2 mb-8">Nos praticiens</h2>
          <div className="flex flex-wrap">
            <div className="w-full md:w-1/2 px-2 mb-16">
              <img src={claire} alt="Claire Bontoux" className="rounded-full h-48 w-48 mb-8"/>
              <h3 className="text-xl pb-1 font-semibold tracking-wider">CLAIRE BONTOUX</h3>
              <p className="mb-8">Masseur-Kinésithérapeute diplômée d'État en 2014</p>
              <p className="mb-2">Formations & spécialités :</p>
              <ul className="list-disc ml-4 mb-8">
                <li>Kinésithérapie respiratoire du nourisson (bronchiolite) - 2015</li>
                <li>École du dos - 2015</li>
                <li>Formation CGE (complexe globale de l'épaule) module 1 & 2 - 2016</li>
                <li>Pilates Matwork niveau 1 - 2016</li>
                <li>Chaîne neuro-méningées - 2017</li>
                <li>Méthode McKenzie lombaire - 2018</li>
                <li>Thérapies manuelles - 2018-2019</li>
                <li>Pilates Matwork niveau 2 & 3 - 2020</li>
              </ul>
              <a href="https://www.doctolib.fr/masseur-kinesitherapeute/marseille/claire-bontoux" target="_blank" rel="noreferrer">
                <button className="btn-primary shadow-lg">Prendre rendez-vous</button>
              </a>
            </div>
            <div className="w-full md:w-1/2 px-2">
              <img src={camille} alt="Camille Guyot" className="rounded-full h-48 w-48 mb-8"/>
              <h3 className="text-xl pb-1 font-semibold tracking-wider">CAMILLE GUYOT</h3>
              <p className="mb-8">Masseur-Kinésithérapeute diplômée d'État en 2014</p>
              <p className="mb-2">Formations & spécialités :</p>
              <ul className="list-disc ml-4 mb-8">
                <li>Kinésithérapie respiratoire du nourisson (bronchiolite) - 2015</li>
                <li>École du dos - 2015</li>
                <li>Formation CGE (complexe globale de l'épaule) module 1 & 2 - 2016</li>
                <li>Pilates Matwork niveau 1 - 2016</li>
                <li>Chaîne neuro-méningées - 2017</li>
                <li>Méthode McKenzie lombaire - 2018</li>
                <li>Thérapies manuelles - 2018-2019</li>
                <li>Pilates Matwork niveau 2 & 3 - 2020</li>
                <li>Développement neuro-moteur du nourisson - 2020</li>
              </ul>
              <a href="https://www.doctolib.fr/masseur-kinesitherapeute/marseille/camille-guyot-marseille" target="_blank" rel="noreferrer">
                <button className="btn-primary shadow-lg">Prendre rendez-vous</button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto pt-32 md:pb-32" id="nous-trouver">
        <h2 className="text-5xl px-6 my-4">Nous trouver</h2>
        <div className="flex flex-wrap items-center">
          <div className="w-full md:w-1/2 px-6">
            <h3 className="tracking-wider mt-4 mb-2 font-bold">L'ADRESSE</h3>
            <p>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" className="inline mr-2"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg>
              7 rue gaston de Flotte, Bâtiment HOM, 13012, Marseille
            </p>
            <h3 className="tracking-wider mt-4 mb-2 font-bold">COMMENT VENIR AU CABINET ?</h3>
            <p className="mb-4">Notre cabinet de kinésithérapie est situé dans la zone commerciale de La Boiseraie.</p>
            <p className="mb-4">Prendre la rue entre INTERMARCHÉ et CARNIVOR, le long de la L2. Au bout de la rue, le bâtiment HOM se trouve sur votre gauche.</p>
            <p className="mb-4">Notre centre est situé au rez-de-chaussée, l'accès se fait par le hall d'entrée.</p>
            <h3 className="tracking-wider mt-4 mb-2 font-bold">ACCÈS</h3>
            <p className="underline">Autoroute : L2 - Sortie Saint-Jean du Désert</p>
            <p className="mb-4">Le centre est directement situé à la sortie Saint-Jean du Désert de la L2.</p>
            <p className="underline">Métro : M1 - Arrêt La Fourragère</p>
            <p className="mb-4">À proximité directe de la sortie de métro La Fourragère (ligne M1), vous accèderez au centre en 10 min. à pieds en passant par le parc de la Molines.</p>
            <p className="underline">Tram : L1 - Arrêt La Boiseraie</p>
            <p className="mb-4">Le centre est également déservi par la ligne 1 du tramway, l'arrêt La Boiseraie se situe à 2 min. à pieds.</p>
            <p className="underline">Bus : Lignes 7B / 7T / 9 / 10 - Terminus La Fourragère</p>
            <p className="mb-4">En passant par le parc de la Molines, le cabinet est facilement accèssible depuis le terminal de bus de la Fourragère.</p>
          </div>
          <div className="w-full md:w-1/2 relative h-96 w-full overflow-hidden">
             <Map />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage