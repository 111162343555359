import React, { Component } from 'react';
import logo from "../images/boiseraie_logo.png"
import { AnchorLink } from "gatsby-plugin-anchor-links"
    
export class MenuModal extends Component {
  constructor(props) {
    super(props);
    this.state = {showModal: false};
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState(prevState => ({
      showModal: !prevState.showModal
    }));
  }
  
  render() {
    const modal = (
      <div className="fixed top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden z-10">
        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
          <div className="pt-5 pb-6 px-5">
            <div className="flex items-center justify-between">
              <div>
                <img src={logo} alt="Logo" className="h-12 w-auto md:h-20"/>
              </div>
              <div className="-mr-2">
                <button type="button" className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 focus:outline-none">
                  <span className="sr-only">Close menu</span>
                  <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" onClick={this.handleClick} fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>
            <div className="mt-6">
              <nav className="grid gap-y-2">
                <AnchorLink to="/#le-centre" title="Le Centre" className="py-2 flex items-center rounded-md green-color-hover">Le centre</AnchorLink>             
                <AnchorLink to="/#nos-specialites" title="Nos spécialités" className="py-2 flex items-center rounded-md green-color-hover">Nos spécialités</AnchorLink>    
                <AnchorLink to="/#notre-equipe" title="Notre équipe" className="py-2 flex items-center rounded-md green-color-hover">Nos praticiens</AnchorLink>        
                <AnchorLink to="/#nous-trouver" title="Nous trouver" className="py-2 flex items-center rounded-md green-color-hover">Nous trouver</AnchorLink>
              </nav>
            </div>
          </div>
          <div className="py-6 px-5 space-y-6">
            <div>
              <button className="btn-primary w-full">
                Prendre rendez-vous
              </button>
            </div>
          </div>
        </div>
      </div>
    );
    
    return (
      <ul className="flex md:hidden">
        <li>
          <svg xmlns="http://www.w3.org/2000/svg" onClick={this.handleClick} width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg>
        </li>
        {this.state.showModal ? modal : ''} 
      </ul>
    );
  }
}

export default MenuModal;